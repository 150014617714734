@charset "utf-8";
@use "../../global/_" as g;
.c-pic-bnr {
    background: #F1D5C7;
    margin: 50px 0 0 0;
    .__img {
        max-width: 100%;
    }
    @include g.smMax {
        margin: 40px 0 0 0;
        .l-row {
            padding: 0;
        }
    }
}

