@charset "utf-8";
@use "../../global/_" as g;
body {
	@include g.font-normal;
    @include g.smMax {
        &._gnav-show_ {
            overflow: hidden;
        }
    }
}
a {
	color: g.$baseColor;
    text-decoration: underline;
    
    .icon-external-link::before,
    .icon-file-pdf-o::before{
		display: inline-block;
        margin: 0 5px;
	}
    .icon-file-pdf-o {
        color: g.$red;
    }
}
sup {
	vertical-align: super;
}
input,
textarea {
	appearance: none;
	max-width: 100%;
}
input::placeholder {
	opacity: .5;
}
button,
input[type="button"],
input[type="submit"] {
	appearance: none;
}
p {
    margin: 15px 0 0 0;
    &._m-0_ {
        margin: 0;
    }
}

[class^="icon-"] .__hidden,
[class*=" icon-"] .__hidden {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    position: absolute;
    top: -999999px;
    left: -999999px;
    width: 1px;
    height: 1px;
    font-size: 1px;
    text-indent: -999999px;
}

@include g.smMin {
	html {
		overflow-y: scroll;
	}
	a[href^="tel:"] {
		pointer-events: none;
	}
}
@include g.smBtMd {
	html {
		font-size: calc(100vw / 128);
	}
}
@include g.smMax {
	input,
	select,
	textarea {
		font-size: g.$fz-m !important;
	}	
}