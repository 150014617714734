@charset "utf-8";
@use "../../global/_" as g;
.m-pagination {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	line-height: 1.4;
    margin: 80px 0 0;

    @include g.smMax {
        margin: 50px 0 0;
    }
	&__item {
		margin: 0 3px 5px;
	}
	&__link {
		display: block;
		width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
		background: g.$white;
		border: 1px solid g.$line-base;
		color: g.$baseColorDarken;
		text-decoration: none;
		border-radius: 50%;
        @include g.transition;
        @include g.font-bold;

		&._current_,
        &:hover {
			background: g.$baseColor;
            border: 1px solid g.$baseColor;
			color: g.$white;
			opacity: 1;
		}
	}
}
