@charset "utf-8";
@use "../../global/_" as g;
.c-point {
    &__item {
        display: flex;
        margin: 20px 0 0 0;
        align-items: center;
        &:first-child {
            margin: 30px 0 0 0;
        }
    }
    &__label {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
        margin: 0 20px 0 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: g.$baseColorDarken;
        background: g.$bg-base;
        font-size: 1.0rem;
        @include g.font-serif-medium;
        line-height: 1.0;
        .__num {
            font-size: g.$fz-xl;
        }
        @include g.smMax {
            margin: 0 10px 0 0;
            .__num {
                font-size: g.$fz-l;
            }
        }
    }
    &__txt {
        flex: 1 1 auto;
        margin: 0;
        font-size: g.$fz-l;
        @include g.font-bold;
    }
}