@charset "utf-8";
@use "../../global/_" as g;
.e-h5 {
	margin: 35px 0 0 0;
	line-height: 1.4;
    @include g.font-serif-medium;
    font-size: g.$fz-xxxl;
    color: g.$baseColorDarken;
    &._black_ {
        color: g.$black;
    }
    @include g.smMax {
        font-size: g.$fz-l;
    }
}
