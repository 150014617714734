@charset "utf-8";
@use "../../global/_" as g;
.m-noscript {
	position: absolute;
	left: 0;
	top: 80px;
	z-index: 20;
	width: 100%;
	padding: 10px 15px;
	background: g.$black;
	color: g.$white;
	font-size: g.$fz-m;
	text-align: center;
}
@include g.smMax {
	.m-noscript {
		top: g.vw-sp(54);
	}
}

