@charset "utf-8";
@use "../../global/_" as g;
.c-entry {
    img {
        max-width: 100%;
        margin: 30px 0 0 0;
    }
    
    h1 {
        margin: 55px 0;
        color: g.$black;
        font-size: 3.0rem;
        @include g.font-serif-medium;
        text-align: center;
        line-height: 1.3;
        position: relative;
        padding: 0 0 30px;
        &::after {
            @include g.centering-elements(true, false);
            width: 80px;
            height: 3px;
            border-bottom: 3px solid g.$baseColor;
            content: "";
            bottom: 0;
        }
        @include g.smMax {
            margin: 30px 0;
            font-size: g.$fz-xxl;
            padding: 0 0 20px;
            &::after {
                width: 60px;
                height: 2px;
                border-bottom: 2px solid g.$baseColor;
            }
        }
    }
    h2 {
        position: relative;
        margin: 80px 0 0;
        @include g.font-serif-medium;
        padding: 0 0 20px;
        color: g.$black;
        font-size: g.$fz-xxxl;
        line-height: 1.4;
        border-bottom: 2px solid #D8D8D8;

        &::after {
            position: absolute;
            left: 0;
            bottom: -2px;
            content: '';
            width: 250px;
            border-bottom: 2px solid g.$baseColor;
        }
        @include g.smMax {
            margin: 40px 0 0;
            font-size: g.$fz-xl;
            &::after {
                width: 130px;
            }
        }
    }
    h3 {
        margin: 35px 0 0 0;
        line-height: 1.4;
        @include g.font-serif-medium;
        font-size: g.$fz-xxl;
        padding: 0 0 0 15px;
        border-left: 2px solid g.$baseColor;
    }
    h4,h5 {
        margin: 35px 0 0 0;
        line-height: 1.4;
        @include g.font-serif-medium;
        font-size: g.$fz-xxxl;
        color: g.$baseColorDarken;
    }
    h5 {
        color: g.$black;
    }
    h6 {
        margin: 15px 0 0;
        font-size: g.$fz-l;
        @include g.font-bold;
    }
    strong {
        @include g.font-bold;
    }
    em {
        font-style: italic;
    }
}
