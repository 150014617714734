@charset "utf-8";
@use "../../global/_" as g;
.e-list-number {
    margin: 30px 0 0 0;
	counter-reset: number 0;
	&__item {
		position: relative;
		padding-left: 1.2em;
		
		&:not(:first-child) {
			margin-top: 5px;
		}
		&::before {
			counter-increment: number;
			content: counter(number)".";
            color: g.$baseColor;
			display: block;
			position: absolute;
			left: 0;
		}
	}
	&._black_ &__item {
		&::before {
			color: g.$black;
		}
	}
}
