@charset "utf-8";
@use "../../../global/_" as g;
.u-ml-xxl {
	margin-left: g.$m-xxl !important;
}
@include g.smMax {
	.u-ml-xxl {
		margin-left: g.$m-xxl / 2 !important;
	}
}
