@charset "utf-8";
@use "../../global/_" as g;
.c-btn-block {
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		&:not(:last-child) {
			margin: 0 80px 0 0;
		}
	}
}
@include g.smBtMd {
	.c-btn-block {
		& > * {
			&:not(:last-child) {
				margin: 0 g.vw(80) 0 0;
			}
		}
	}
}
@include g.smMax {
	.c-btn-block {
		flex-direction: column-reverse;

		& > * {
			&:not(:last-child) {
				margin: g.vw-sp(20) 0 0;
			}
		}
	}
}
