@charset "utf-8";
@use "../../../global/_" as g;
.c-index-ttl01 {
    font-size: 3.6rem;
    @include g.font-serif-bold;
    color: g.$baseColorDarken;
    line-height: 1.5;
    .__en {
        font-size: g.$fz-s;
        @include g.font-serif;
    }
    @include g.smMax {
        font-size: g.$fz-xxxl;
        line-height: 1.7;
        .__en {
            font-size: g.$fz-xxxs;
        }
    }
}
