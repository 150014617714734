@charset "utf-8";
@use "../../global/_" as g;
.m-table {
	width: 100%;
	margin: 30px 0 0 0;
	th,
	td {
		padding: 25px;
		border: 1px solid g.$line-gray;
		text-align: left;
		vertical-align: middle;
        @include g.smBtMd {
            padding: 15px;
        }
	}
	._al-l_ {
		text-align: left;
	}
	._al-r_ {
		text-align: right;
	}
	._al-c_ {
		text-align: center;
	}
	._nowrap_ {
		white-space: nowrap;
	}
    ._bg-base_ {
        background: #D6CBAF;
        &:not(:last-child) {
            border-right: 1px solid g.$white;
        }
        &._line-gray_ {
            border: 1px solid g.$line-gray;
        }
    }
    ._fw-r_ {
        @include g.font-normal;
    }
    ._bg-baselight_ {
        background: g.$bg-base;
    }
    th {
        @include g.font-bold;
        text-align: center;
    }
    
    &._line-ud_ {
        th,td {
            border: 1px solid g.$baseColor;
            border-right: none;
            border-left: none;
            vertical-align: top;
        }
        th {
            padding: 15px 20px 15px 0;
            @include g.smMax {
                padding: 15px 10px 15px 0;
            }
        }
        td {
            padding: 15px 0 15px 20px;
            @include g.smMax {
                padding: 15px 0 15px 10px;
            }
        }
        tr:first-child th,
        tr:first-child td {
            border: none;   
        }
    }
    @include g.smMax {
        th,td {
            padding: 10px 15px;
        }
        //SP 1カラム -------------------------------
		&._sm-col1_ {
			display: block;
			border-top: 1px solid g.$line-gray;
			thead,
			tbody,
			tr,
			th,
			td {
				display: block;
                border-top: none;
			}
			th {
				background: g.$bg-base;
				color: g.$text-black;
			}
		}
		
		//横長 -------------------------------
		&._wide_ {
			border: none;
			display: block;

			th,
			td {
				display: block;
				padding: 5px 8px;
				border-bottom-width: 0;
				border-right-width: 0;
				text-align: center;
			}
			th{  
				width: auto;
				text-align: left;
			}
		}
		&._wide_ &__head {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		&._wide_ &__body {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		&._wide_ &__head th:last-child,
		&._wide_ &__body td:last-child {
			border-bottom-width: 1px;
		}
		
		//リスト風 -------------------------------
		&._list_ {
			border: none;

			tr {
				display:block;
				margin: 0 0 20px;
			}
			th {
				display: block;
			}
			td {
				display: list-item;
				margin: 0 0 0 20px;
				padding: 5px 0 0;
				border: none;
				background: none;
			}
		}
        
    }
}
