@charset "utf-8";
@use "../../../global/_" as g;
.u-mb-xl {
	margin-bottom: g.$m-xl !important;
}
@include g.smMax {
	.u-mb-xl {
		margin-bottom: g.$m-xl / 2 !important;
	}
}
