@charset "utf-8";
@use "../../../global/_" as g;
.u-pb-l {
	padding-bottom: g.$m-l !important;
}
@include g.smMax {
	.u-pb-l {
		padding-bottom: g.$m-l / 2 !important;
	}
}
