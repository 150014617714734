@charset "utf-8";
@use "../../global/_" as g;
.e-h1 {
	position: relative;;
	padding: 80px 0 0;
	color: g.$baseColorDarken;
	font-size: 3.4rem;
    @include g.font-serif-medium;
	text-align: center;
	line-height: 1.4;
    background: url("/common/img/common/bg_ttl01_pc.png") center center no-repeat;
    background-size: cover;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include g.smBtMd {
        padding: 70px 0 0;
        height: 200px;
    }
    @include g.smMax {
        padding: 60px 0 0;
        height: 160px;
        font-size: 2.6rem;
        background: url("/common/img/common/bg_ttl01_sp.png") left center no-repeat;
        background-size: cover;
    }
}
