@charset "utf-8";
@use "../../global/_" as g;
.e-catch01 {
	@include g.font-serif-medium;
    font-size: g.$fz-xxxl;
    line-height: 1.5;
    &._base_ {
        color: g.$baseColor;
    }
    @include g.smMax {
        font-size: g.$fz-xl;
    }
}
