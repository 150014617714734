@charset "utf-8";
@use "../../../global/_" as g;
.c-index-bnr {
    padding: 110px 0 0 0;
    overflow-x: hidden;
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 74%;
        height: 66%;
        content: "";
        background: g.$bg-base;
        display: block;
        z-index: -1;
    }
    @include g.smMax {
        padding: 50px 0 0 0;
        &::before {
            width: 80%;
            height: 78%;
        }
    }
    &__main {
        max-height: 320px;
        position: relative;
        .__img {
            width: 66.6%;
            object-fit: cover;
        }
        @include g.smMax {
            max-height: inherit;
            .__img {
                width: 100%;
            }
        }
        &__text {
            @include g.font-serif-medium;
            display: block;
            text-decoration: none;
            color: g.$black;
            margin: 0;
            @include g.smMin {
                @include g.centering-elements(false, true);
                right: 0;
                width: 38%;
                transition: background .5s;
                &:hover {
                    background: g.$baseColorLight;
                    .e-text-link {
                        color: g.$baseColorDarken;
                        .__ico {
                            background: g.$baseColorDarken;
                            color: g.$white;
                        }
                    }
                }
            }
            .__txt {
                margin: 10px 0 0 0;
                @include g.smMax {
                    font-size: 1.5rem;
                }
            }
            @include g.smMax {
                width: 90%;
                z-index: 5;
                position: relative;
                margin: -20px 0 0 auto;
            }
        }
    }
    &__sub {
        margin: 110px 0 0 0;
        .__link {
            @include g.hover-opacity;
        }
        @include g.smMax {
            margin: 40px 15px 0;
        }
    }
    &__line {
        @include g.hover-opacity;
        margin: 110px 0 0 0;
        display: block;
        .__img {
            max-width: 100%;
        }
        @include g.smMax {
            margin: 80px 0 0;
        }
    }
}
