@charset "utf-8";
[data-aos=fade-up] {
	transform: translate3d(0, 20px, 0)
}
[data-aos=fade-down] {
	transform: translate3d(0, -20px, 0)
}
[data-aos=fade-right] {
	transform: translate3d(-20px, 0, 0)
}
[data-aos=fade-left] {
	transform: translate3d(20px, 0, 0)
}
[data-aos=fade-up-right] {
	transform: translate3d(-20px, 20px, 0)
}
[data-aos=fade-up-left] {
	transform: translate3d(20px, 20px, 0)
}
[data-aos=fade-down-right] {
	transform: translate3d(-20px, -20px, 0)
}
[data-aos=fade-down-left] {
	transform: translate3d(20px, -20px, 0)
}
