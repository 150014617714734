@charset "utf-8";
@use "../../../global/_" as g;
.u-pt-xxl {
	padding-top: g.$m-xxl !important;
}
@include g.smMax {
	.u-pt-xxl {
		padding-top: g.$m-xxl / 2 !important;
	}
}
