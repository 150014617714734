@charset "utf-8";
@use "../../global/_" as g;
.c-step {
    padding: 15px 0 0 0;
    &__item {
        display: flex;
        padding: 35px 0;
        border-bottom: 1px solid g.$line-gray;
    }
    &__label {
        margin: 0 40px 0 0;
        color: g.$baseColor;
        font-size: g.$fz-xl;
        @include g.font-serif-medium;
        @include g.smMax {
            font-size: g.$fz-l;
            margin: 0 15px 0 0;
        }
    }
    &__txt {
        flex: 1 1 auto;
        margin: 0;
        .__ttl {
            font-size: g.$fz-l;
            @include g.font-bold;
            display: block;
        }
    }
}