* {
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	font: inherit;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	text-decoration: none;
	vertical-align: baseline;
}

html {
	font-size: 62.5%;
	width: 100%;
	height: 100%;
}

body {
	font-size: 10px;
	font-size: 1.0rem;
	line-height: 1;
	position: relative;
	text-size-adjust: 100%;
	font-smoothing: antialiased;
	width: 100%;
	height: 100%;
}

article,
aside,
dialog,
figure,
footer,
header,
main,
menu,
nav,
section {
	display: block;
}

audio,
canvas,
video {
	display: inline-block;
}

hr {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

input,
select {
	vertical-align: middle;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
}

img {
	vertical-align: bottom;
}