@charset "utf-8";
@use "../../../global/_" as g;
.u-pl-xxl {
	padding-left: g.$m-xxl !important;
}
@include g.smMax {
	.u-pl-xxl {
		padding-left: g.$m-xxl / 2 !important;
	}
}
