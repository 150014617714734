@charset "utf-8";
@use "../../global/_" as g;
.l-row {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    &._narrow01_ {
        max-width: 1040px;
    }
    @include g.smMax {
        max-width: 100%;
        padding: 0 15px;
    }
}