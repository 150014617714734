@charset "utf-8";
@use "../../../global/_" as g;
.u-pb-xl {
	padding-bottom: g.$m-xl !important;
}
@include g.smMax {
	.u-pb-xl {
		padding-bottom: g.$m-xl / 2 !important;
	}
}
