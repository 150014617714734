@charset "utf-8";
@use "../../global/_" as g;
.e-a-txt-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: .2em 50px .2em 0;

	&::before,
	&::after {
		position: absolute;
		content: '';
		@include g.transition;
	}
	&::before {
		right: 0;
		width: 40px;
		height: 1px;
		background: g.$black;
	}
	&::after {
		right: 0;
		background: g.$black;
		@include g.circle(5);
		@include g.iehack {
			transform: translateY(-2px);
		}
	}
}
@include g.smMin {
	.e-a-txt-arrow {
		&:hover {
			&::before {
				animation: a-txt-arrow-line .8s;
			}
			&::after {
				animation: a-txt-arrow-circle .8s;
			}
		}
	}
}
@include g.smMax {
	.e-a-txt-arrow {
	}
}
