@charset "utf-8";
@use "../../global/_" as g;
.e-list-circle {
    margin: 30px 0 0 0;
	&__item {
		position: relative;
		margin-bottom: 5px;
		padding-left: 15px;
		
		&::before {
			position: absolute;
			top: 0.7em;
			left: 0;
			display: block;
			content: '';
            background: g.$baseColor;
			@include g.circle(6);
		}
	}
	&._black_ &__item {
		&::before {
			background: g.$text-black;
		}
	}
}