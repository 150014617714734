@charset "utf-8";
@use "../../global/_" as g;
.c-fix-menu {
    @include g.smMin {
        right: 0;
        @include g.centering-elements(false, true);
        position: fixed;
        z-index: 5;
    }
	@include g.smMax {
        bottom: 0;
        @include g.centering-elements(true, false);
        position: fixed;
        z-index: 5;
        height: 50px;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    &__link {
        color: g.$white;
        width: 80px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: g.$fz-xxs;
        line-height: 1.3;
        text-decoration: none;
        @include g.hover-opacity;
        &._web_ {
            background: g.$baseColorDarken;
            border-top-left-radius: 10px;
            margin: 0 0 5px;
        }
        &._line_ {
            background: g.$ocher;
            border-bottom-left-radius: 10px;
        }
        .__ico {
            margin: 0 0 5px;
        }
        @include g.smMax {
            flex-direction: row;
            width: calc(100% / 3);
            height: 50px;
            &._web_ {
                border-top-left-radius: 0;
                margin: 0;
                .__ico {
                    max-width: 15px;
                }
            }
            &._line_ {
                background: g.$white;
                color: g.$black;
                border-bottom-left-radius: 0;
            }
            &._tel_ {
                 background: g.$ocher;
            }
            .__ico {
                margin: 0 5px 0 0;
                max-width: 23px;
                max-height: 17px;
            }
        }
    }
}
