@charset "utf-8";
@use "../../../global/_" as g;
.c-index-menu {
    background: url("/common/img/index/bg_menu_01_pc.png") no-repeat;
    background-size: cover;
    @include g.smMax {
        background: url("/common/img/index/bg_menu_01_sp.png") no-repeat;
        background-size: cover;
    }
    &__list {
        margin: 50px 0 0 0;
    }
    &__item {
        background: g.$white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px 10px 60px 10px;
        @include g.transition;
        position: relative;
        text-align: center;
        border: 3px solid g.$white;
        text-decoration: none;
        color: g.$black;
        @include g.smMax {
            padding: 10px 5px 40px 5px;
        }
        .__ttl {
            @include g.font-serif-medium;
            font-size: g.$fz-l;
            margin: 10px 0 0 0;
            .__small {
                font-size: g.$fz-s;
            }
            @include g.smMax {
                font-size: g.$fz-m;
                .__small {
                    font-size: g.$fz-xxxs;
                }
            }
        }
        &::after {
            content: "\e902";
            @include g.font-icon;
            border: 1px solid g.$line-base;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: g.$fz-xxxs;
            color: g.$baseColorDarken;
            @include g.centering-elements(true, false);
            bottom: 20px;
            width: 25px;
            height: 25px;
            @include g.transition;
            @include g.smMax {
                bottom: 10px;
                width: 20px;
                height: 20px;
                font-size: 0.8rem;
            }
        }
        &:hover {
            border: 3px solid g.$baseColorDarken;
            &::after {
                background: g.$baseColorDarken;
                color: g.$white;
                border: 1px solid g.$baseColorDarken;
            }
        }
        @include g.smMax {
            .__ico {
                max-height: 40px;
                max-width: 45px;
                margin: 0 auto;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .c-grid .__col img {
        max-width: inherit;
    }
}