@charset "utf-8";
@use "../../global/_" as g;
.c-lnav {
	width: 100%;
	margin: 50px auto 0;
	display: flex;
	flex-wrap: wrap;
	&._jc-c_ {
		justify-content: center;
	}
    @include g.smMax {
        margin: 30px auto 0;
    }
    &__item {
        width: calc((100% - 30px) / 4);
        margin-bottom: 10px;
        @include g.smMin {
            &:not(:nth-child(4n)) {
                margin: 0 10px 10px 0;
            }
        }
        @include g.smMax {
            width: calc((100% - 15px) / 2);
            &:not(:nth-child(2n)) {
                margin: 0 10px 10px 0;
            }
        }
        ._col3_ & {
            @include g.smMin {
                width: calc((100% - 20px) / 3);
                &:not(:nth-child(4n)) {
                    margin: 0 0 10px 0;
                }
                &:not(:nth-child(3n)) {
                    margin: 0 10px 10px 0;
                }
            }
        }
    }
	&__link {
		background: #FBFBF7;
		color: g.$black;
        @include g.font-bold;
		text-decoration: none;
		display: flex;
		align-items: center;
		position: relative;
		@include g.transition;
		padding: 5px 25px 5px 15px;
		line-height: 1.5;
		height: 100%;
        min-height: 70px;
        border: 1px solid g.$line-base;
        .__mark {
            max-width: 35px;
            max-height: 35px;
            margin: 0 15px 0 0;
        }
		.__ico {
			@include g.centering-elements(false, true);
			right: 10px;
			color: g.$baseColor;
            font-size: g.$fz-xxs;
		}
        &:hover {
            background: g.$line-base;
        }
        @include g.smMax {
            min-height: 60px;
        }
	}
    @include g.smMax {
        &-sm {
            width: 100%;
        }
    }
}
