@charset "utf-8";
@use "../../global/_" as g;
.l-gnav {
    @include g.smMin {
        display: flex;
        align-items: center;
		&__list {
			display: flex;
			align-items: center;
			font-size: g.$fz-m;
			line-height: 1.4;
            @include g.font-serif-medium;
            @include g.lgMax {
                font-size: g.$fz-s;
            }
		}
		&__item {
			display: flex;
			align-items: center;
            margin: 0 10px;
		}
		&__link {
			position: relative;
			color: g.$black;
            text-decoration: none;
			@include g.transition;

			&::after {
				position: absolute;
				bottom: -0.77em;
				left: 0;
				content: '';
				width: 100%;
				height: 1px;
				background: g.$baseColor;
				transform: scale(0, 1);
				transform-origin: center top;
			}
			&:not(._current_) {
				&::after {
					@include g.transition(.3s);
				}
			}
			&:hover,
			&._current_ {
				&::after {
					transform: scale(1, 1);
				}
			}
			._black_ & {
				color: g.$white;
			}
		}
        &__btn {
            &__item {
                color: g.$baseColor;
                @include g.font-serif-bold;
                margin: 0 0 0 10px;
                font-size: 2.6rem;
                text-decoration: none;
                .__label {
                    font-size: g.$fz-s;
                }
            }
        }
	}
    @include g.smMax {
        position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: #FBFBF7;
		opacity: 0;
		pointer-events: none;
		@include g.transition;
        margin: 60px 0 0 0;
        padding: 0 0 130px;
        overflow-y: scroll;
        &__list {
            border-bottom: 1px solid g.$line-base;
        }
		&__item {
			border-top: 1px solid g.$line-base;
			font-size: g.$fz-m;
		}
		&__link {
            display: block;
            position: relative;
            padding: 10px 15px;
			color: g.$baseColorDarken;
			text-decoration: none;
            &::after {
                content: "\e902";
                @include g.font-icon;
                @include g.centering-elements(false, true);
                right: 15px;
                font-size: g.$fz-xxxs;
            }
            &._down_ {
                &::after {
                    content: "\e906";
                    font-size: g.$fz-s;
                    @include g.transition;
                }
                &._drop_ {
                    &::after {
                        content: "\e905";
                        @include g.transition;
                    }
                }
            }
            &._pdf_ {
                &::after {
                    content: "\f1c1";
                    font-size: g.$fz-s;
                }
            }
		}
        &__dropdown {
            border-top: 1px solid g.$line-base;
            display: none;
            background: g.$bg-base;
            width: 100%;
        }
        &__subitem {
            padding: 10px 15px;
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid g.$line-base;
            position: relative;
            text-decoration: none;
            color: g.$baseColorDarken;
            &::after {
                right: 15px;
                @include g.centering-elements(false, true);
                content: "\e902";
                font-family: 'icomoon';
                font-size: g.$fz-xxxs;
                color: g.$baseColorDarken;
            }
            &:last-child {
                border-bottom: none;
            }
        }
		._gnav-show_ & {
			z-index: 1;
			opacity: 1;
			pointer-events: auto;
		}
        .__ttl {
            color: g.$baseColorDarken;
            font-size: g.$fz-l;
            @include g.font-bold;
            text-align: center;
            margin: 30px 0 0;
        }
        &__btn {
            margin: 0 auto;
            width: 80%;
            &__item {
                color: g.$white;
                margin: 20px 0 0;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                line-height: 1.0;
                text-decoration: none;
                &::after {
                    right: 15px;
                    @include g.centering-elements(false, true);
                    content: "\e902";
                    font-family: 'icomoon';
                    font-size: g.$fz-xxxs;
                }
                .__ico {
                    margin: 0 10px 0 0;
                    max-width: 18px;
                }
                &._tel_ {
                    background: g.$ocher;
                    @include g.font-serif-bold;
                    font-size: g.$fz-xl;
                    align-items: flex-end;
                    .__label {
                        font-size: g.$fz-xxs;
                    }
                }
                &._line_ {
                    background: #00B900;
                }
                &._web_ {
                    background: g.$baseColorDarken;
                }
            }
            &__close {
                margin: 50px 0 0;
                text-align: center;
                font-size: 4.0rem;
                line-height: 1.0;
                .__link {
                    text-decoration: none;
                }
            }
        }
	}
}