@charset "utf-8";
@use "../../../global/_" as g;
.e-form-check {
    .mwform-checkbox-field {
		display: inline-block;
		margin-right: 15px;
		margin-left: 0!important;
	}
	.__elem {
		display: none;
		&:checked + .__txt,
        &:checked + .mwform-checkbox-field-text {
			&::after {
				opacity: 1;
			}
		}
	}
	.__txt,
    .mwform-checkbox-field-text {
		position: relative;
		padding: 0 0 0 20px;
		cursor: pointer;
		&::before {
			left: 0;
			width: 14px;
			height: 14px;
			border: 2px solid #d9d9d9;
			content: '';
			@include g.centering-elements(false, true);
		}
		&::after {
			left: 1px;
            color: g.$baseColor;
			font-family: 'icomoon';
			font-size: 1.6rem;
			content: '\f00c';
			font-weight: 600;
			opacity: 0;
			@include g.centering-elements(false, true);
		}
	}
    @include g.smMax {
        .mwform-checkbox-field {
			margin-right: 10px;
		}
    }
}
