@charset "utf-8";
@use "../../../global/_" as g;
.u-pl-l {
	padding-left: g.$m-l !important;
}
@include g.smMax {
	.u-pl-l {
		padding-left: g.$m-l / 2 !important;
	}
}
