@charset "utf-8";
@use "../../../global/_" as g;
.e-form-select {
    position: relative;
	display: inline-block;
	border: 1px solid #dee2e6;
	padding: 5px 0;
    &::after {
		position: absolute;
        top: 0.23em;
        @include g.font-icon;
		right: 8px;
		content: "\f107";
		color: g.$baseColor;
		font-size: g.$fz-l;
		z-index: -1;
	}
	& > select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		width: 100%;
		box-sizing: border-box;
		padding-left: 8px;
		padding-right: 35px;
		border: none;
		background-color: transparent;
		border-radius: 0;
		outline: none;
        overflow-y: auto;
		&::-ms-expand {
			display: none;
		}
    }
    & > option {
        width: 100%;
    }
}
