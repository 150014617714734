@charset "utf-8";
@use "../../global/_" as g;
.c-snav {
	&-head {
		height: 60px;
        color: g.$white;
        background: g.$baseColor;
        @include g.font-serif-medium;
        font-size: g.$fz-xl;
        display: flex;
        align-items: center;
        padding: 0 15px;
	}
	&-list {
        background: g.$bg-base;
        border: 1px solid g.$line-base;
        padding: 15px 0;
		&__link {
            color: g.$black;
			display: flex;
			align-items: center;
			padding: 5px 15px;
			text-decoration: none;
            @include g.transition;
            &:hover {
                text-decoration: underline;
                color: g.$baseColor;
            }
		}
	}	
}
@include g.smMax {
	.c-snav {
	}
}
