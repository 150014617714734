@charset "utf-8";
@use "../../global/_" as g;
.e-figure {
	margin: 30px 0 0 0;
	display: table;
	margin-left: auto;
	margin-right: auto;

	img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
	figcaption {
		display: table-caption;
		caption-side: bottom;
		margin-top: g.$m-xxs;
        font-size: 1.5rem;

		&._al-c_ {
			text-align: center;
		}
        &._fw-b_ {
            @include g.font-bold;
        }
	}
    @include g.smMax {
        margin: 20px 0 0 0;
        figcaption {
            margin-top: g.$m-xxxs;
        }
    }
}