@charset "utf-8";
@use "../../../global/_" as g;
.e-form-textarea {
	display: inline-block;
	width: 100%;
	height: 200px;
	padding: 10px 10px;
	border: 1px solid g.$line-gray;
	color: g.$text-black;

	&._error_ {
		border-color: g.$red;
	}
}
