@charset "utf-8";
@use "../../global/_" as g;
.e-text-link {
    @include g.font-serif-medium;
    color: g.$black;
    display: inline-flex;
    align-items: center;
    margin: 5px 0 0 0;
    justify-content: flex-end;
    text-decoration: none;
    @include g.transition;
    .__ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border: 1px solid g.$line-base;
        border-radius: 50%;
        color: g.$baseColorDarken;
        font-size: g.$fz-xxxs;
        margin: 0 0 0 10px;
        transition: background .5s;
    }
    &:hover {
        color: g.$baseColorDarken;
        .__ico {
            background: g.$baseColorDarken;
            color: g.$white;
        }
    }
    &._small_ {
        .__ico {
            width: 25px;
            height: 25px;
        }
    }
    @include g.smMax {
        font-size: 1.5rem;
        .__ico {
            width: 30px;
            height: 30px;
        }
    }
}
