@charset "utf-8";
@use "../../global/_" as g;
.e-h3 {
	position: relative;
	margin: 80px 0 0;
    @include g.font-serif-medium;
	padding: 0 0 20px;
	color: g.$black;
	font-size: g.$fz-xxxl;
	line-height: 1.4;
    border-bottom: 2px solid #D8D8D8;

	&::after {
        position: absolute;
        left: 0;
		bottom: -2px;
		content: '';
		width: 250px;
		border-bottom: 2px solid g.$baseColor;
	}
    @include g.smMax {
        margin: 40px 0 0;
        font-size: g.$fz-xl;
        &::after {
            width: 130px;
        }
    }
    .__mark {
        margin: 0 15px 0 0;
    }
}