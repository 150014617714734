@charset "utf-8";
@use "../../../global/_" as g;
.u-pt-l {
	padding-top: g.$m-l !important;
}
@include g.smMax {
	.u-pt-l {
		padding-top: g.$m-l / 2 !important;
	}
}
