@charset "utf-8";
@use "../../global/_" as g;
.m-box {
	padding: 30px;
	background: g.$white;
	margin: 50px 0 0 0;
    .c-grid & {
        margin: 0;
    }
    &._shadow_ {
       box-shadow: 0 0 10px rgba(#756044, 0.14); 
    }
    &._baselight_ {
        background: g.$bg-base;
    }
    &._line-base_ {
        border: 1px solid g.$line-base;
    }
    &._line-red_ {
        border: 1px solid g.$red;
    }
    
    p:first-child,
    .e-h4:first-child {
        margin: 0;
    }
    
    @include g.smBtMd {
        padding: 20px;
    }
    @include g.smMax {
        padding: 15px 20px;
    }
}