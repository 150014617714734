@charset "utf-8";
@use "../../global/_" as g;
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?32ap4u');
  src:  url('/common/icon/fonts/icomoon.eot?32ap4u#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?32ap4u') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?32ap4u') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?32ap4u') format('woff'),
    url('/common/icon/fonts/icomoon.svg?32ap4u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e907";
}
.icon-minus:before {
  content: "\e905";
}
.icon-plus:before {
  content: "\e906";
}
.icon-list-chack:before {
  content: "\e904";
}
.icon-down:before {
  content: "\e900";
}
.icon-left:before {
  content: "\e901";
}
.icon-right:before {
  content: "\e902";
}
.icon-up:before {
  content: "\e903";
}
.icon-check:before {
  content: "\f00c";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
