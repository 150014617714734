@charset "utf-8";
@use "../../global/_" as g;
.c-grid {
	display: flex;
	flex-wrap: wrap;
    margin: 30px 0 0 0;
	
	&:not([class*="_col"]) {
		margin-left: calc(#{g.$margin} / 2 * -1);
		margin-right: calc(#{g.$margin} / 2 * -1);
        @include g.smMax {
			margin-left: calc(#{g.$sm-margin} / 2 * -1);
			margin-right: calc(#{g.$sm-margin} / 2 * -1);
		}
	}
	.__col {
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		
		@for $i from 1 through g.$maxcol {
			&._col-#{$i}_ {
				@include g.column($i);
			}
		}
		img {
			max-width: 100%;
		}
	}
}
@include g.smMin {
	.c-grid {
		.__col {
			@for $i from 1 through g.$maxcol {
				&._col-lg-#{$i}_ {
					@include g.column($i);
				}
			}
		}
		
		//2カラム -----------------------------------
		&._col2_ {
			.__col {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc((100% - #{g.$margin}) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: g.$margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$margin} - 1px) / 2);
				}
			}
		}

		//3カラム -----------------------------------
		&._col3_ {
			.__col {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc((100% - #{g.$margin} * 2) / 3);
				&:not(:nth-child(3n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+3)) {
					margin-top: g.$margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$margin} * 2 - 1px) / 3);
				}
			}
		}

		//4カラム -----------------------------------
		&._col4_ {
			.__col {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc((100% - #{g.$margin} * 3) / 4);
				&:not(:nth-child(4n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+4)) {
					margin-top: g.$margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$margin} * 3 - 1px) / 4);
				}
			}
		}

		//5カラム -----------------------------------
		&._col5_ {
			.__col {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc((100% - #{g.$margin} * 4) / 5);
				&:not(:nth-child(5n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+5)) {
					margin-top: g.$margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$margin} * 4 - 1px) / 5);
				}
			}
		}
        //6カラム -----------------------------------
		&._col6_ {
			.__col {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc((100% - #{g.$margin} * 5) / 6);
				&:not(:nth-child(6n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+6)) {
					margin-top: g.$margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$margin} * 5 - 1px) / 6);
				}
			}
		}
	}
}
@include g.smBtMd {
	.c-grid {
		.__col {
			@for $i from 1 through g.$maxcol {
				&._col-md-#{$i}_ {
					@include g.column($i);
				}
			}
		}
		
		//2カラム -----------------------------------
		&._col2_ {
			.__col {
				flex-basis: calc((100% - #{g.$tab-margin}) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: g.$tab-margin;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: g.$tab-margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$tab-margin} - 1px) / 2);
				}
				@include g.ffhack {
					flex-basis: calc((100% - #{g.$tab-margin} - 1px) / 2);
				}
			}
		}

		//3カラム -----------------------------------
		&._col3_ {
			.__col {
				flex-basis: calc((100% - #{g.$tab-margin} * 2) / 3);
				&:not(:nth-child(3n+1)) {
					margin-left: g.$tab-margin;
				}
				&:not(:nth-child(-n+3)) {
					margin-top: g.$tab-margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$tab-margin} * 2 - 1px) / 3);
				}
				@include g.ffhack {
					flex-basis: calc((100% - #{g.$tab-margin} * 2 - 1px) / 3);
				}
			}
		}

		//4カラム -----------------------------------
		&._col4_ {
			.__col {
				flex-basis: calc((100% - #{g.$tab-margin} * 3) / 4);
				&:not(:nth-child(4n+1)) {
					margin-left: g.$tab-margin;
				}
				&:not(:nth-child(-n+4)) {
					margin-top: g.$tab-margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$tab-margin} * 3 - 1px) / 4);
				}
				@include g.ffhack {
					flex-basis: calc((100% - #{g.$tab-margin} * 3 - 1px) / 4);
				}
			}
		}

		//5カラム -----------------------------------
		&._col5_ {
			.__col {
				flex-basis: calc((100% - #{g.$tab-margin} * 4) / 5);
				&:not(:nth-child(5n+1)) {
					margin-left: g.$tab-margin;
				}
				&:not(:nth-child(-n+5)) {
					margin-top: g.$tab-margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$tab-margin} * 4 - 1px) / 5);
				}
				@include g.ffhack {
					flex-basis: calc((100% - #{g.$tab-margin} * 4 - 1px) / 5);
				}
			}
		}
        //6カラム -----------------------------------
		&._col6_ {
			.__col {
				flex-basis: calc((100% - #{g.$tab-margin} * 5) / 6);
				&:not(:nth-child(6n+1)) {
					margin-left: g.$tab-margin;
				}
				&:not(:nth-child(-n+6)) {
					margin-top: g.$tab-margin;
				}
				@include g.iehack {
					flex-basis: calc((100% - #{g.$tab-margin} * 5 - 1px) / 6);
				}
				@include g.ffhack {
					flex-basis: calc((100% - #{g.$tab-margin} * 5 - 1px) / 6);
				}
			}
		}		
	}
}
@include g.smMax {
	.c-grid {
        margin-top: 20px;
		.__col {
			@for $i from 1 through g.$maxcol {
				&._col-#{$i}_ {
					@include g.sp-column($i);
				}
				&._col-sm-#{$i}_ {
					@include g.sp-column($i);
				}
			}
		}
		
		//2～5カラム（SP表示は2カラムを基本とする） -----------------------------------
		&._col2_,
		&._col3_,
		&._col4_,
		&._col5_,
        &._col6_{
			.__col {
				flex-basis: calc((100% - 15px) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: 15px;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: 15px;
				}
			}
		}
		
		
		//スマホ強制1カラム -----------------------------------
		&._sm-col1_ {
			.__col {
				flex: 0 0 100%;
				width: 100%;
				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 15px;
				}
			}
		}
	}
}
