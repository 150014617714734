@charset "utf-8";
@use "../../../global/_" as g;
.u-pb-xxxl {
	padding-bottom: g.$m-xxxl !important;
}
@include g.smMax {
	.u-pb-xxxl {
		padding-bottom: g.$m-xxxl / 2 !important;
	}
}
