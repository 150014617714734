@charset "utf-8";
@use "../../global/_" as g;
.l-contents {
	position: relative;
	z-index: 1;
    @include g.smMin {
		margin: 0 0 130px;
		&__inner {
			display: flex;
			justify-content: space-between;
            margin: 50px 0 0 0;
		}
		&._col2_ &__body {
			flex: 1 1 auto;
			margin: 0 4.16% 0 0;
		}
		&._col2_ &__side {
			flex: 0 0 240px;
		}
	}
    @include g.smMax {
		margin: 0 0 70px;
		&__inner {
			display: flex;
			flex-direction: column;
		}
		&._col2_ &__body {
			margin: 0 0 30px;
		}
	}
}
