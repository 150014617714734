@charset "utf-8";
@use "../../global/_" as g;
.e-h4 {
	margin: 35px 0 0 0;
	line-height: 1.4;
    @include g.font-serif-medium;
    font-size: g.$fz-xxl;
    padding: 0 0 0 15px;
    border-left: 2px solid g.$baseColor;
    &._link_ &__link {
        @include g.transition;
        position: relative;
        text-decoration: none;
        color: g.$text-black;
        @include g.smMin {
            &:hover {
                color: g.$baseColorDarken;
                .__ico {
                    background: g.$baseColorDarken;
                    color: g.$white;
                }
            }
        }
        .__ico {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border: 1px solid g.$line-base;
            border-radius: 50%;
            color: g.$baseColorDarken;
            font-size: g.$fz-xxxs;
            margin: 0.3em 0 0 10px;
            transition: background .5s;
        }
    }
    @include g.smMax {
        font-size: g.$fz-xl;
        &._link_ &__link {
            .__ico {
                font-size: 0.8rem;
                width: 22px;
                height: 22px;
            }
        }
    }
}