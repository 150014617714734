@charset "utf-8";
@use "../../global/_" as g;
.c-pagetop {
	position: absolute;
	right: 20px;
	z-index: 5;
	transform: translateZ(0);

	&._fixed_ {
		position: fixed;
		bottom: 20px;
        @include g.smMax {
            bottom: 60px;
        }
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: g.$baseColorDarken;
		color: g.$white;
		font-size: g.$fz-m;
        border-radius: 50%;
		transform: translateZ(0);
        text-decoration: none;
        @include g.smMax {
            width: 40px;
		    height: 40px;
            font-size: g.$fz-s;
        }
	}
}