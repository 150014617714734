@charset "utf-8";
@use "../../../global/_" as g;
.u-al-fl {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: flex-start !important;
}
@include g.smMax {
	.u-al-l {
	}
}
