@charset "utf-8";
@use "../../../global/_" as g;
.u-mt-l {
	margin-top: g.$m-l !important;
}
@include g.smMax {
	.u-mt-l {
		margin-top: g.$m-l / 2 !important;
	}
}
