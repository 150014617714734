@charset "utf-8";
@use "../../global/_" as g;
.c-flex {
	display: flex;
	margin: 30px 0 0;

	&__item {
		flex: 0 0 auto;
		max-width: 50%;

		img {
			max-width: 100%;
		}
		& + * {
			& > *:first-child {
				margin-top: 0;
			}
		}
	}

	// 画像左寄せ
	&._left_ {
		flex-direction: row;
	}
	&._left_ &__item {
		margin-right: 20px;

		@include g.smMax {
			margin-right: 15px;
		}
	}
    &._left_._wide_ &__item {
		margin-right: 40px;

		@include g.smMax {
			margin-right: 15px;
		}
	}

	// 画像右寄せ
	&._right_ {
		flex-direction: row-reverse;
	}
	&._right_ &__item {
		margin-left: 20px;

		@include g.smMax {
			margin-left: 15px;
		}
	}
    &._right_._wide_ &__item {
		margin-left: 40px;

		@include g.smMax {
			margin-left: 15px;
		}
	}

	@include g.smMax {
        margin: 20px 0 0 0;
		// スマホ 1カラム（画像上）
		&._sm-fd-c_ {
			flex-direction: column;
		}
		&._sm-fd-c_ &__item {
			align-self: center;
			margin: 0 auto;
            text-align: center;
		}
        &._sm-fd-c_._wide_ &__item {
			margin: 0 auto;
		}
		&._sm-fd-c_ &__item + * {
			margin-top: 30px;
		}

		//スマホ 1カラム（画像下）
		&._sm-fd-cr_ {
			flex-direction: column-reverse;
		}
		&._sm-fd-cr_ &__item {
			align-self: center;
			margin: 30px auto 0;
		}
	}
}
