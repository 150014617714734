@charset "utf-8";

$num: 5;

// ============================================================
// breakpoint
// ============================================================
$sxs: 376;
$xs:  416; // mobile
$sm:  769; //mobile
$md: 1025; //tablet(大)
$lg: 1281;

$xs-1: $xs - 1;
$sm-1: $sm - 1;
$md-1: $md - 1;
$lg-1: $lg - 1;

$xsMin: 'min-width:' + $xs + 'px';
$smMin: 'min-width:' + $sm + 'px';
$mdMin: 'min-width:' + $md + 'px';
$lgMin: 'min-width:' + $lg + 'px';

$xsMax: 'max-width:' + $xs-1 + 'px';
$smMax: 'max-width:' + $sm-1 + 'px';
$mdMax: 'max-width:' + $md-1 + 'px';
$lgMax: 'max-width:' + $lg-1 + 'px';


// ============================================================
// color
// ============================================================
$baseColor: #AB7E32;
$baseColorDarken: #7E5B1F;
$baseColorLight: #F9F3E4;

$text-black: #000;

$line-gray: #C9C9C9;
$line-base: #D6CBAF;
$bg-gray: #efefef;
$bg-base: #F6F4EA;

$red: #dd0000;
$white: #ffffff;
$gray: #818181;
$black: #000000;
$ocher: #C8A531;


// ============================================================
// font size
// ============================================================
$fz-xxxs: 1.0rem;
$fz-xxs: 1.2rem;
$fz-xs: 1.3rem;
$fz-s: 1.4rem;
$fz-m: 1.6rem;
$fz-l: 1.8rem;
$fz-xl: 2.0rem;
$fz-xxl: 2.2rem;
$fz-xxxl: 2.4rem;


// ============================================================
// マージンサイズ
// ============================================================
$m-xxxs: 10px;
$m-xxs: 15px;
$m-xs: 20px;
$m-s: 25px;
$m-m: 30px;
$m-l: 60px;
$m-xl: 80px;
$m-xxl: 100px;
$m-xxxl: 150px;


// ============================================================
// grid
// ============================================================
$maxcol: 12;
$max-width: 100%;
$margin: 40px; //PC用 カラム間余白
$tab-margin: 3.125vw; //PC用 カラム間余白
$sm-margin: 10px; //SP用 カラム間余白