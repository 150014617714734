@charset "utf-8";
@use "../../global/_" as g;
.c-blog-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__link {
        text-decoration: none;
        width: 100%;
        @include g.smMin {
            @include g.hover-opacity;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc((100% - #{g.$margin} * 2) / 3);
            &:not(:nth-child(3n+1)) {
                margin-left: g.$margin;
            }
            &:not(:nth-child(-n+3)) {
                margin-top: g.$margin;
            }
            @include g.iehack {
                flex-basis: calc((100% - #{g.$margin} * 2 - 1px) / 3);
            }
            @include g.smBtMd {
                flex-basis: calc((100% - #{g.$margin}) / 3);
                &:not(:nth-child(3n+1)) {
                    margin-left: calc(#{g.$margin} / 2);
                }
            }
            .__img {
                width: 100%;
                object-fit: cover;
                height: 14.75vw;
                max-height: 177px;
            }
            &:hover {
                .__txt {
                    text-decoration: underline;
                }
            }   
        }
        @include g.smMax {
            flex: 0 0 100%;
            width: 100%;
            display: flex;
            &:not(:nth-child(2n+1)) {
                margin-left: 0;
            }
            &:not(:nth-child(-n+1)) {
                margin-top: 15px;
            }
        }
    }
    @include g.smMax {
        &__img {
            max-width: 40%;
            margin: 0 10px 0 0;
            flex: 0 0 40%;
            .__img {
                width: 100%;
                object-fit: cover;
                height: 23.47vw;
                max-height: 88px;
            }
        }
    }
    .__label {
       background: g.$baseColorDarken;
        color: g.$white;
        text-align: center;
        min-width: 120px;
        font-size: g.$fz-s;
        display: inline-block;
        margin: 15px 0 0 0;
        @include g.smMax {
            margin: 0;
        }
    }
    .__txt {
        @include g.font-bold;
        color: g.$black;
        @include g.smMax {
            font-size: g.$fz-s;
            margin: 10px 0 0;
        }
    }
}
