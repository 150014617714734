@charset "utf-8";
@use "../../../global/_" as g;
.c-index-news {
    padding: 110px 0 0 0;
    display: flex;
    justify-content: space-between;
    @include g.smMax {
        display: block;
        padding: 60px 0 0 0;
    }
    &__calendar {
        max-width: 260px;
        flex: 0 0 260px;
        @include g.smMax {
            max-width: 100%;
        }
    }
    &__list {
        max-width: 840px;
        flex: 1 1 auto;
        margin: 0 0 0 40px;
        .c-news-list {
            overflow-y: scroll;
            height: 250px;
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                background-color: #E8E8E8;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: g.$baseColor;
                border-radius: 10px;
            }
        }
        @include g.smMax {
            max-width: 100%;
            margin: 40px 0 0;
            .c-news-list {
                height: 200px;
            }
        }
    }
}