@charset "utf-8";
@use "../../../global/_" as g;
.c-index-ttl02 {
    font-size: 2.6rem;
    @include g.font-serif-bold;
    color: #232323;
    display: flex;
    align-items: flex-end;
    line-height: 1.1;
    .__small {
        font-size: 1.5rem;
        @include g.font-serif-medium;
        color: g.$baseColor;
        margin: 0 0 0 10px;
        line-height: 1.5;
    }
    @include g.smMax {
        font-size: g.$fz-xxl;
        .__small {
            font-size: g.$fz-s;
        }
    }
    &._white_ {
        color: g.$white;
        .__small {
            color: g.$white;
        }
    }
    &._center_ {
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        .__small {
            margin: 10px 0 0 0;
        }
    }
    &._lage_ {
        font-size: 3.2rem;
        @include g.smMax {
            font-size: 2.6rem;
        }
    }
    &._fz-xxxl_ {
        font-size: g.$fz-xxxl;
        .__small {
            font-size: g.$fz-xs;
        }
    }
}
