@charset "utf-8";
@use "../../../global/_" as g;
.e-form-radio {
	.__elem {
		display: none;
		&:checked + .__txt,
        &:checked + .mwform-radio-field-text {
			&::after {
				opacity: 1;
			}
		}
	}
	.__txt,
	.mwform-radio-field-text {
		position: relative;
		padding: 0 0 0 25px;
		cursor: pointer;
		&::before {
			left: 0;
			border: 2px solid g.$line-gray;
			content: '';
			@include g.centering-elements(false, true);
			@include g.circle(14);
		}
		&::after {
			left: 5px;
			background: g.$baseColor;
			content: '';
			opacity: 0;
			@include g.centering-elements(false, true);
			@include g.circle(8);
		}
	}
	&._error_ {
		.__txt {
			&::before {
				border-color: g.$red;
			}
		}
	}
}
