@charset "utf-8";
@use "../../../global/_" as g;
.u-mt-xxl {
	margin-top: g.$m-xxl !important;
}
@include g.smMax {
	.u-mt-xxl {
		margin-top: g.$m-xxl / 2 !important;
	}
}
