@charset "utf-8";
@use "../../../global/_" as g;
.u-pa-xxxl {
	padding: g.$m-xxxl !important;
}
@include g.smMax {
	.u-pa-xxxl {
		padding: g.$m-xxxl / 2 !important;
	}
}
