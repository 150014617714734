@charset "utf-8";
@use "../../global/_" as g;
.l-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    padding: 0 15px;
    transition: background .5s;
    @include g.smMax {
        z-index: 10;
        padding: 0 0 0 15px;
        height: 60px;
    }
    &Logo {
        flex: 0 0 335px;
        margin: 0 25px 0 0;
        &__link {
            @include g.hover-opacity;
            display: block;
            .__elem {
                width: 100%;
            }
        }
        @include g.lgMax {
            flex: 0 0 160px;
        }
    }
    &Menu {
        @include g.smMin {
            display: none;
        }
        @include g.smMax {
            display: block;
            order: 2;
            position: relative;
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            background: g.$baseColorDarken;
            color: g.$white;

            &__inner {
                position: relative;
                width: 36px;
                height: 50px;
            }
            &__item {
                display: inline-block;
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background: g.$white;
                @include g.radius(2);
                @include g.transition;

                &:nth-of-type(1) { top: 8px; }
                &:nth-of-type(2) { top: 17px; }
                &:nth-of-type(3) { bottom: 22px; }
            }
            &__text {
                &::after {
                    content: "MENU";
                    bottom: 0px;
                    color: g.$white;
                    font-size: g.$fz-xxs;
                    @include g.font-serif;
                    @include g.centering-elements(true, false);
                }
            }
            &._active_ &__item {
                background: g.$white;

                &:nth-of-type(1) { transform: translateY(8px) rotate(-315deg); }
                &:nth-of-type(2) { opacity: 0; }
                &:nth-of-type(3) { transform: translateY(-10px) rotate(315deg); } 
            }
            &._active_ &__text {
				&::after {
					content: "CLOSE";
				}
			}
        }
    }
    &._white_,
    ._gnav-show_ & {
        background: g.$white;
    }
}