@charset "utf-8";
@use "../../global/_" as g;
.t-wrap {
	position: relative;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto 1fr auto;
	grid-template-areas: "c-header" "l-contents" "c-footer";
	min-height: 100%;
	color: g.$text-black;
	font-size: g.$fz-m;
	letter-spacing: 0.03em;
	line-height: 1.8;

	.c-header { grid-area: c-header; }
	.l-contents { grid-area: l-contents; }
	.c-footer { grid-area: c-footer; }
    
    @include g.smMax {
        ._gnav-show_ & {
        }
    }
}
