@charset "utf-8";
@use "../../../global/_" as g;
.u-pr-xxl {
	padding-right: g.$m-xxl !important;
}
@include g.smMax {
	.u-pr-xxl {
		padding-right: g.$m-xxl / 2 !important;
	}
}
