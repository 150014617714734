@charset "utf-8";
@use "../../global/_" as g;
.m-pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
    align-items: center;
	margin: 80px 0 0;
	line-height: 1.4;
    @include g.smMax {
        margin: 50px 0 0;
    }
	
	&__item {
		margin: 0 20px;
        @include g.smMax {
            margin: 0 10px;
        }
	}
	&__link {
		display: block;
        @include g.font-serif;
        color: g.$black;
        text-decoration: none;
        ._list_ & {
            border: 1px solid g.$line-base;
            padding: 10px 40px;
            border-radius: 25px;
            @include g.smMin {
                @include g.transition;
                &._current_,
                &:hover {
                    background: g.$baseColor;
                    border: 1px solid g.$baseColor;
                    color: g.$white;
                    opacity: 1;
                }
            }
            @include g.smMax {
                padding: 10px 30px;
            }
        }
        ._prev_ &,
        ._next_ & {
            @include g.font-serif-medium;
            display: flex;
            align-items: center;
            .__ico {
                border: 1px solid g.$line-base;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                border: 1px solid g.$line-base;
                border-radius: 50%;
                color: g.$baseColorDarken;
                font-size: 0.8rem;
                transition: background .5s;
            }
            @include g.smMin {
                &._current_,
                &:hover {
                    color: g.$baseColorDarken;
                    .__ico {
                        background: g.$baseColor;
                        border: 1px solid g.$baseColor;
                        color: g.$white;
                        opacity: 1;
                    }
                }
            }
        }
        ._prev_ & .__ico {
            margin: 0 10px 0 0;
        }
        ._next_ & .__ico {
            margin: 0 0 0 10px;
        }
	}
}
