@charset "utf-8";
@use "../../../global/_" as g;
.c-index-visual {
    width: 100%;
	position: relative;
    background: url(/common/img/index/bg_visual_pc.png) left bottom no-repeat;
    background-size: cover;
    padding-bottom: 130px;
    @include g.smMax {
        background: url(/common/img/index/bg_visual_sp.png) left bottom no-repeat;
        background-size: cover;
        padding-bottom: 80px;
    }
    &__img {
        display: block;
        margin: 90px 0 0 auto;
        width: 77%;
        position: relative;
        .__elem {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include g.smMax {
            width: 80%;
        }
    }
    &__text {
        color: g.$baseColorDarken;
        font-size: 3.4rem;
        @include g.font-serif-medium;
        @include g.centering-elements(false, true);
        left: 11%;
        z-index: 1;
        @include g.smMax {
            left: 15px;
            font-size: g.$fz-xxxl;
            line-height: 1.5;
        }
    }
}
