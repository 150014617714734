@charset "utf-8";
@use "../../../global/_" as g;
.u-mb-xxl {
	margin-bottom: g.$m-xxl !important;
}
@include g.smMax {
	.u-mb-xxl {
		margin-bottom: g.$m-xxl / 2 !important;
	}
}
