@charset "utf-8";
@use "../../../global/_" as g;
.u-mr-xl {
	margin-right: g.$m-xl !important;
}
@include g.smMax {
	.u-mr-xl {
		margin-right: g.$m-xl / 2 !important;
	}
}
