@charset "utf-8";
@use "../../global/_" as g;
.e-h2 {
	margin: 55px 0;
	color: g.$black;
	font-size: 3.0rem;
    @include g.font-serif-medium;
	text-align: center;
	line-height: 1.3;
    position: relative;
    padding: 0 0 30px;
    &::after {
        @include g.centering-elements(true, false);
        width: 80px;
        height: 3px;
        border-bottom: 3px solid g.$baseColor;
        content: "";
        bottom: 0;
    }
    @include g.smMax {
        margin: 30px 0;
        font-size: g.$fz-xxl;
        padding: 0 0 20px;
        &::after {
            width: 60px;
            height: 2px;
            border-bottom: 2px solid g.$baseColor;
        }
    }
}