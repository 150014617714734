@charset "utf-8";
@use "../../global/_" as g;
.c-breadcrumb {
	padding: 10px 0;
	font-size: 1.5rem;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1240px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
                color: g.$baseColor;
			}
            .c-breadcrumb__link {
                color: g.$baseColorDarken;
                &:hover {
                    text-decoration: underline;
                }
            }
		}
	}
}
@include g.smMax {
	.c-breadcrumb {
		margin: 0 0 25px;
		font-size: g.$fz-xxxs;
	
		&__inner {
			padding: 0 15px;
		}
	}
}
