@charset "utf-8";
@use "../../../global/_" as g;
.u-mt-xxxl {
	margin-top: g.$m-xxxl !important;
}
@include g.smMax {
	.u-mt-xxxl {
		margin-top: g.$m-xxxl / 2 !important;
	}
}
