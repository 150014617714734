@charset "utf-8";
@use "../../global/_" as g;
.c-faq {
    &__item {
        padding: 30px 0;
        border-bottom: 1px solid g.$line-gray;
        &:last-child {
            border-bottom: none;
        }
    }
    &__qt,
    &__as {
        display: flex;
        padding: 0 0 0 65px;
        position: relative;
        @include g.smMax {
            padding: 0 0 0 55px;
        }
        .__label {
            width: 45px;
            height: 45px;
            flex: 0 0 45px;
            margin: 0 20px 0 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: g.$fz-xl;
            @include g.font-serif-medium;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__qt {
        font-size: g.$fz-l;
        line-height: 1.5;
        @include g.font-bold;
        .__label {
            top: -8px;
            color: g.$white;
            background: g.$ocher;
        }
        @include g.smMax {
            top: -3px;
        }
    }
    &__as {
        margin: 30px 0 0 0;
        .__label {
            top: -3px;
            color: g.$baseColorDarken;
            background: g.$bg-base;
        }
        @include g.smMax {
            margin: 15px 0 0 0;
        }
    }
}