@charset "utf-8";
@use "../../../global/_" as g;
.u-pt-xl {
	padding-top: g.$m-xl !important;
}
@include g.smMax {
	.u-pt-xl {
		padding-top: g.$m-xl / 2 !important;
	}
}
