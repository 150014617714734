@charset "utf-8";
@use "../../global/_" as g;
.c-news-list {
    padding: 0 30px 0 0;
	&__item {
		display: flex;
		align-items: center;
		padding: 20px 0;

		&:not(:last-child) {
			border-bottom: 1px solid g.$line-base;
		}
	}
	&__date {
		flex: 0 0 auto;
		margin: 0 25px 0 0;
	}
    &__link {
        color: g.$black;
    }
    @include g.smMin {
        &__link {
            @include g.transition;
            &:not(span) {
                text-decoration: underline;
                &:hover {
                    color: g.$baseColor;
                }
            }
         }
    }
    @include g.smMax {
        &__item {
			display: block;
			padding: 15px 0;
		}
        &__date {
            display: block;
        }
		&__link {
			width: 100%;
			margin: 10px 0 0;
		}
    }
}
