@charset "utf-8";
@use "../../global/_" as g;
.e-list-chack {
	&__item {
		position: relative;
		margin-top: 10px;
		padding-left: 35px;
        font-size: g.$fz-l;
        @include g.font-bold;
		
		&::before {
			position: absolute;
            top: -0.6rem;
			left: 0;
			display: block;
			content: '\e904';
            color: g.$ocher;
            @include g.font-icon;
            font-size: g.$fz-xxxl;
		}
        @include g.smMax {
            margin-top: 5px;
            font-size: g.$fz-m;
        }
	}
	&._black_ &__item {
		&::before {
			background: g.$text-black;
		}
	}
}
