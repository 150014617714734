@charset "utf-8";
@use "../../../global/_" as g;
.e-form-text {
	display: inline-block;
	width: 100%;
	padding: 5px 10px;
	border: 1px solid g.$line-gray;
	color: g.$text-black;
	
	&._w-m_ {
		max-width: 350px;
	}
	&._w-s_ {
		max-width: 100px;
	}
	&._w-a_ {
		width: auto;
	}
	&._error_ {
		border-color: g.$red;
	}
}
