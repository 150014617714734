@charset "utf-8";
@use "../../../global/_" as g;
.u-mb-xxxl {
	margin-bottom: g.$m-xxxl !important;
}
@include g.smMax {
	.u-mb-xxxl {
		margin-bottom: g.$m-xxxl / 2 !important;
	}
}
