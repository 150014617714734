@charset "utf-8";
@use "../vars/_" as v;

// ============================================================
//  vw 
// ============================================================
@function sm_vw($size, $viewport:$smDesignW){
  $rate: math.div(100 , $viewport);
  @return $rate * $size * 1vw;
}
@function md_vw($size, $viewport:$mdDesignW){
  $rate: math.div(100 , $viewport);
  @return $rate * $size * 1vw;
}
@function lg_vw($size, $viewport:$lgDesignW){
  $rate: math.div(100 , $viewport);
  @return $rate * $size * 1vw;
}

@function sm_vh($size, $viewport:$smDesignW){
  $rate: math.div(100 , $viewport);
  @return $rate * $size * 1vh;
}
@function md_vh($size, $viewport:$mdDesignW){
  $rate: math.div(100 , $viewport);
  @return $rate * $size * 1vh;
}
@function lg_vh($size){
  $rate: math.div(100 , 760);
  @return $rate * $size * 1vh;
}

// ============================================================
//  media query 
// ============================================================
@mixin xsMin {
  @media print, screen and (v.$xsMin) {
    @content;
  }
}
@mixin smMin {
  @media print, screen and (v.$smMin) {
    @content;
  }
}
@mixin mdMin {
  @media print, screen and (v.$mdMin) {
    @content;
  }
}
@mixin lgMin {
  @media print, screen and (v.$lgMin) {
    @content;
  }
}
@mixin smBtMd {
  @media screen and (v.$smMin) and (v.$mdMax){
    @content;
  }
}
@mixin xsMax {
  @media screen and (v.$xsMax) {
    @content;
  }
}
@mixin smMax {
  @media screen and (v.$smMax) {
    @content;
  }
}
@mixin mdMax {
  @media screen and (v.$mdMax) {
    @content;
  }
}
@mixin lgMax {
  @media screen and (v.$lgMax) {
    @content;
  }
}
@mixin max-screen($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}
@mixin min-screen($breakpoint) {
  @media print, screen and (min-width: $breakpoint) {
    @content;
  }
}
@mixin screen($breakpoint-min, $breakpoint-max) {
  @media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
    @content;
  }
}


// ============================================================
//  font 
// ============================================================
@mixin font-normal {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
}

@mixin font-bold {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 500;
}

@mixin font-serif {
	font-family: 'Noto Serif JP', serif;
	font-weight: 500;
}

@mixin font-serif-medium {
	font-family: 'Noto Serif JP', serif;
	font-weight: 600;
}

@mixin font-serif-bold {
	font-family: 'Noto Serif JP', serif;
	font-weight: 700;
}
@mixin font-icon {
	font-family: 'icomoon';
}

// ============================================================
//  vw
// ============================================================
@function vw($value, $viewport:1280) {
	@return ($value / $viewport) * 100 + vw;
}
@function vw-sp($value, $viewport_sp:375) {
	@return ($value / $viewport_sp) * 100 + vw;
}
// 使用例
// width: g.vw-sp(100); width:100px 
// font-size: g.vw-sp(24); フォントサイズ 24px


// ============================================================
//  grid 
// ============================================================
@mixin column($col){
	width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$margin});
  margin-left: calc(#{v.$margin} / 2);
  margin-right: calc(#{v.$margin} / 2);
  margin-bottom: v.$margin;
}
@mixin sp-column($col){
	width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$sm-margin});
  margin-left: calc(#{v.$sm-margin} / 2);
  margin-right: calc(#{v.$sm-margin} / 2);
  margin-bottom: v.$sm-margin;
}



// ============================================================
//  other 
// ============================================================
@mixin hidden {
	font-size: 1px;
	position: absolute;
	top: -999999px;
	left:-999999px;
	clip: rect(1px, 1px, 1px, 1px);
	width: 1px;
	height: 1px;
	display: block;
	opacity: 0;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition($duration: .5s) {
	transition: $duration all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}
@mixin hover-opacity($duration: .4s, $opacity: 0.7) {
	transition: $duration all;

	&:hover {
		opacity: $opacity;
	}
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin iehack() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
@mixin ffhack() {
	@-moz-document url-prefix() {
		@content;
	}
}
// ============================================================
//  keyframes
// ============================================================
@keyframes a-txt-arrow-line {
	0% {
		right: 0;
		width: 40px;
	}
  50% {
		right: 40px;
		width: 0;
	}
	100% {
		right: 0;
		width: 40px;
	}
}
@keyframes a-txt-arrow-circle {
	0% { right: 0; }
  50% { right: 40px; }
	100% { right: 0; }
}

@keyframes visual-scroll {
	0% { height: 100px; opacity: 1; }
	80% { height: 0; opacity: 1; }
	85% { height: 0; opacity: 0; }
	90% { height: 100px; opacity: 0; }
	100% { height: 100px; opacity: 1; }
}