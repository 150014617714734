@charset "utf-8";
@use "../../global/_" as g;
.l-footer {
	line-height: 1.6;
	background: #3B2806;
	font-size: 1.5rem;
    @include g.smMax {
        margin: 0 0 50px;
    }
	&__inner {
		max-width: 1240px;
		margin: 0 auto;
		padding: 40px 20px 70px;
        @include g.smMax {
            padding: 40px 15px;
        }
	}
    &__add {
        display: flex;
        align-items: center;
        color: g.$white;
        font-size: 1.5rem;
        @include g.smMax {
            display: block;
        }
    }
    &__logo {
        margin: 0 30px 0 0;
		&__link {
			display: block;
			width: 326px;
			.__elem {
				width: 100%;
			}
		}
	}
    &__body {
        display: flex;
		justify-content: space-between;
        margin: 45px 0 0 0;
        @include g.smMax {
            display: block;
            margin: 0;
        }
    }
    &__info {
        font-size: 1.5rem;
        color: g.$white;
        white-space: nowrap;
        .__label {
            font-size: g.$fz-s;
            border: 1px solid g.$white;
            width: 90px;
            height: 25px;
            line-height: 25px;
            margin: 5px 10px 0 0;
            display: inline-block;
            text-align: center;
        }
        &__tel {
            font-size: 2.6rem;
            color: g.$ocher;
            @include g.font-serif-bold;
            margin: 10px 0 0 0;
            display: block;
            text-decoration: none;
            .__label {
                border: none;
                width: auto;
                margin: 0;
            }
            @include g.smMax {
                position: relative;
                background: g.$ocher;
                color: g.$white;
                width: 90%;
                margin: 30px auto 0;
                text-align: center;
                font-size: g.$fz-xl;
                padding: 10px;
                
                .__label {
                    font-size: g.$fz-xxs;
                }
                &::after {
                    content: "\e902";
                    @include g.font-icon;
                    font-size: 0.8rem;
                    @include g.centering-elements(false, true);
                    right: 15px;
                }
            }
        }
    }
	&__nav {
		display: flex;
		justify-content: center;
        flex: 1 1 auto;
        margin: 0 30px;
		&__block {
			text-align: left;
            &:not(:last-child) {
                margin: 0 60px 0 0;
            }
		}
		&__item {
			margin: 0 0 10px;
		}
		&__link {
			text-decoration: none;
			position: relative;
            color: g.$white;
            @include g.smMin {
                &:hover {
                    text-decoration: underline;
                }
            }
		}
        @include g.smMax {
            margin: 40px -15px 0;
            display: block;
            border-bottom: 1px solid #4E4027;
            &__block:not(:last-child) {
                margin: 0;
            }
            &__item {
                border-top: 1px solid #4E4027;
                margin: 0;
            }
            &__link {
                display: block;
                width: 100%;
                padding: 15px;
                &::after {
                    content: "\e902";
                    @include g.font-icon;
                    font-size: 0.8rem;
                    @include g.centering-elements(false, true);
                    right: 15px;
                }
                &._pdf_ {
                    &::after {
                        content: "\f1c1";
                        font-size: g.$fz-s;
                    }
                }
            }
        }
	}
	&__sns {
		&__ttl {
			color: g.$ocher;
			line-height: 1.0;
			border-right: 1px solid g.$ocher;
			display: inline-block;
            font-size: 1.5rem;
            padding: 0 10px 0 0;
		}
		&__list {
			display: flex;
            margin: 15px 0 0 0;
		}
		&__item {
			&:not(:last-child) {
				margin: 0 15px 0 0;
			}
		}
		&__link {
			text-decoration: none;
			@include g.transition;
			&:hover {
				opacity: 0.7;
			}
		}
        @include g.smMax {
            margin: 40px 0 0;
            display: flex;
            align-items: center;
            &__ttl {
                margin: 0 15px 0 0;
            }
            &__list {
                margin: 0;
            }
        }
	}
	&__copyright {
		text-align: center;
		border-top: 1px solid #4E4027;
		padding: 20px 0;
		font-size: g.$fz-xs;
        color: #D6C4A3;
        @include g.smMax {
            font-size: g.$fz-xxs;
        }
	}
}