@charset "utf-8";
@use "../../../global/_" as g;
.c-index-contents {
    padding: 100px 0 0;
    position: relative;
    &::before {
        z-index: -1;
        width: 100%;
        height: 0;
        background: url(/common/img/index/bg_contents_01.svg) no-repeat;
        background-size: cover;
        content: "";
        position: absolute;
        top: 0;
        padding-bottom: 24.83%;
        left: 0;
    }
    &::after {
        z-index: -1;
        width: 100%;
        height: 0;
        background: url(/common/img/index/bg_contents_02.svg) bottom no-repeat;
        background-size: cover;
        content: "";
        position: absolute;
        bottom: 0;
        padding-bottom: 37.35%;
        left: 0;
        @include g.smMax {
            padding-bottom: 49.87%;
            background: url(/common/img/index/bg_contents_02_sp.svg) bottom no-repeat;
            background-size: cover;
            bottom: 35%;
        }
    }
    &__sns {
        border: 1px solid #B4A377;
        display: grid;
        gap: 0 0; 
        @include g.smMin {
            grid-template-columns: 1fr 1fr 1fr 1fr; 
            grid-template-rows: 1fr; 
            grid-template-areas: "block01 block02 block03 block04";
        }
        @include g.smMax {
            grid-template-columns: 1fr 1fr 1fr; 
            grid-template-rows: auto;
            grid-template-areas: 
                "block01 block01 block01"
                "block02 block03 block04";
        }
        ._ttl_ { grid-area: block01; }
        ._line_ { grid-area: block02; }
        ._inst_ { grid-area: block03; }
        ._tiktok_ { grid-area: block04; }
        
        &__item {
            background: g.$white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 30px 50px 30px 15px;
            position: relative;
            text-decoration: none;
            .__ttl {
                font-size: g.$fz-xl;
                @include g.font-serif-medium;
                color: g.$black;
            }
            .__ico {
                margin: 0 10px 0 0;
            }
            &:not(:last-child) {
                border-right: 1px solid #B4A377;
            }
            &._ttl_ {
                background: g.$bg-base;
                flex-direction: column;
                padding: 30px;
                .__ttl {
                    font-size: g.$fz-xxl;
                }
                .__en {
                    color: g.$baseColor;
                    font-size: g.$fz-xxs;
                    @include g.font-serif;
                    margin: 0;
                }
            }
            &:not(:first-child) {
                transition: background .5s;
                &::after {
                    content: "\e902";
                    @include g.font-icon;
                    border: 1px solid g.$line-base;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: g.$fz-xxxs;
                    color: g.$baseColorDarken;
                    width: 25px;
                    height: 25px;
                    @include g.transition;
                }
                @include g.smMin {
                    &:hover {
                        background: g.$baseColorLight;
                        &::after {
                            background: g.$baseColorDarken;
                            color: g.$white;
                            border: 1px solid g.$baseColorDarken; 
                        }
                    }
                    &::after {
                        @include g.centering-elements(false, true);
                        right: 20px;
                    }
                }
            }
            @include g.smMax {
                padding: 25px 15px 40px;
                flex-direction: column;
                &._ttl_ {
                    border-right: none;
                    border-bottom: 1px solid #B4A377;
                    flex-direction: row;
                    padding: 20px;
                    justify-content: flex-start;
                    line-height: 1.0;
                    align-items: flex-end;
                    .__en {
                        margin: 0 0 0 10px;
                    }
                }
                .__ttl {
                    font-size: g.$fz-m;
                }
                .__ico {
                    margin: 0 0 5px;
                    max-width: 30px;
                    max-height: 30px;
                }
                &:not(:first-child) {
                    &::after {
                        @include g.centering-elements(true, false);
                        bottom: 10px;
                        width: 18px;
                        height: 18px;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
    
    &__message {
        margin: 100px 0 0 0;
        overflow-x: hidden;
        position: relative;
        padding: 80px 0;
        &::before {
            position: absolute;
            right: 0;
            top: 0;
            width: 67%;
            height: 100%;
            content: "";
            background: g.$bg-base;
            display: block;
        }
        &__inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .__img {
                z-index: 2;
                width: 47%;
            }
        }
        &__text {
            z-index: 2;
            width: 48%;
        }
        @include g.smMax {
            margin: 60px 0 0 0;
            padding: 20px 0 0;
            &::before {
                width: calc(100% - 15px);
                left: 15px;
                height: 92%;
            }
            &__inner {
                flex-direction: column-reverse;
                .__img {
                    width: calc(100% + 30px);
                    margin: 30px 0 0 -30px;
                }
            }
            &__text {
                width: 100%;
                padding: 0 15px;
            }
        }
    }
    
    &__blog {
        margin: 100px 0 0 0;
        &__inner {
            display: flex;
        }
        &__list {
            margin: 0;
        }
        &__ttl {
            white-space: nowrap;
            margin: 0 30px 0 0;
            .e-text-link {
                margin: 40px 0 0 0;
            }
        }
        @include g.smMax {
            margin: 60px 0 0 0;
            &__inner {
                display: block;
            }
            &__ttl {
                margin: 0;
                .e-text-link {
                    margin: 10px 0 0 0;
                }
            }
            &__list {
                margin: 30px 0 0;
            }
        }
    }
    &__bnr {
        margin: 100px 0 0 0;
        &__item {
            @include g.hover-opacity;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child {
                .c-index-contents__bnr__link {
                    background-image: url("/common/img/index/bnr_index_01.png");
                }
            }
            &:nth-child(2) {
                .c-index-contents__bnr__link {
                    background-image: url("/common/img/index/bnr_index_02.png");
                }
            }
            &:last-child {
                .c-index-contents__bnr__link {
                    background-image: url("/common/img/index/bnr_index_03.png");
                }
            }
        }
        &__link {
            background-repeat: no-repeat;
            background-size: cover;
            padding-bottom: 39%;
            height: 0;
            width: 100%;
            
            &::after {
                content: "\e902";
                @include g.font-icon;
                border: 1px solid g.$white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: g.$fz-xxxs;
                color: g.$white;
                @include g.centering-elements(false, true);
                right: 20px;
                width: 25px;
                height: 25px;
                @include g.transition;
            }
            .c-index-ttl02 {
                @include g.centering-elements(true, true);
            }
        }
        @include g.smMax {
            margin: 40px 0 0 0;
        }
    }
}
