@charset "utf-8";
@use "../../global/_" as g;
.u-w-a {
	width: auto !important;
}

@include g.smMax {
	.u-sp-w-a {
		width: auto !important;
	}
}