@charset "utf-8";
@use "../../../global/_" as g;
.u-mt-xl {
	margin-top: g.$m-xl !important;
}
@include g.smMax {
	.u-mt-xl {
		margin-top: g.$m-xl / 2 !important;
	}
}
