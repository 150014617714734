@charset "utf-8";
@use "../../global/_" as g;
.c-form-list {
	&__item {
		&:not(:last-child) {
			margin: 0 0 5px;
		}
	}
}
@include g.smMax {
	.c-form-list {
	}
}
