@charset "utf-8";
@use "../../global/_" as g;
.c-contact-alert {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 50px;
	padding: 20px;
	border: 1px solid g.$red;
	background: rgba(g.$red, .06);
	color: g.$red;
	font-size: g.$fz-l;
	@include g.font-bold;

	.__ico {
		margin: 0 5px 0 0;
		font-size: g.$fz-xxxl;
	}
}
@include g.smBtMd {
	.c-contact-alert {
		margin: 0 0 g.vw(50);
		padding: g.vw(20);
	}
}
@include g.smMax {
	.c-contact-alert {
		margin: 0 0 g.vw-sp(30);
		padding: g.vw-sp(15);
		font-size: g.$fz-m;
	
		.__ico {
			margin: 0 5px 0 0;
			font-size: g.$fz-xl;
		}
	}
}
