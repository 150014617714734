@charset "utf-8";
@use "../../global/_" as g;
.c-menu {
    margin: 75px 0 0 0;
    &__list {
        @include g.smMin {
            margin: 0 -20px;
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__item {
        margin: 0 20px;
        width: calc((100% - 80px) / 2);
        border-bottom: 1px solid g.$line-gray;
        @include g.smMax {
            width: 100%;
            margin: 0;
        }
    }
    &__link {
        display: block;
        padding: 30px 30px 30px 0;
        width: 100%;
        height: 100%;
        text-decoration: none;
        @include g.font-bold;
        color: g.$black;
        @include g.transition;
        position: relative;
        .__ico {
            font-size: g.$fz-xxxs;
            border: 1px solid g.$line-base;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: g.$baseColorDarken;
            width: 25px;
            height: 25px;
            @include g.transition;
            @include g.centering-elements(false, true);
            right: 0;
        }
        @include g.smMin {
            &:hover {
                color: g.$baseColorDarken;
                .__ico {
                    background: g.$baseColorDarken;
                    color: g.$white;
                    border: 1px solid g.$baseColorDarken; 
                } 
            }
        }
        @include g.smMax {
            padding: 20px 30px 20px 0;
        }
    }
}